import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Settings } from 'luxon';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import pxios from './apis/pxios';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import './index.scss';
import 'swiper/swiper.scss';
import { store } from './store';

Settings.defaultLocale = 'ko';
ReactGA.initialize('G-GDFS2NZVV3');

if (process.env.REACT_APP_ENV === 'production') {
  location.replace('https://heydealer.co.kr/');
  Sentry.init({
    dsn: 'https://9ba201efd67740de9cb9930c8b309d32@o44449.ingest.sentry.io/5978454',
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 0.2,
  });
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: url }) => {
        const { data } = await pxios.get(`${url}`);
        return data;
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Root />
    </Provider>
  </QueryClientProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
